import Swal from 'sweetalert2';

function checkCookieExistence(cookieName) {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();

        // Check if the cookie starts with the provided cookie name
        if (cookie.indexOf(cookieName + '=') === 0) {
            return true;
        }
    }

    return false;
}

if (!checkCookieExistence('popup')) {
    Swal.fire({
        focusCancel: true,
        html: '<img src="/images/popup.jpg" style="max-width:100%;height:auto;cursor:pointer" id="popup-image" />',
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: 'Chiudi',
        customClass: {
            cancelButton: 'btn btn-primary',
        }
    }).then(() => {
        const currentDate = new Date();
        const exp = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 7);

        document.cookie = "popup=1; expires=" + exp.toUTCString();
    });

    document.querySelector('#popup-image').addEventListener('click', () => {
        Swal.close();
        window.location.href = '/';
    })
}